/*
 * focusedelements.js
 * v. 1.1
 */

$(function() {

    $window = $(window);

    // class of objects
    function Element(_selector, _offset) {

        this.selector = _selector;
        this.offset = _offset;

        // Gets % of object in the viewport
        this.percentOnScreen = function() {
            var viewport_top = $window.scrollTop(),
                viewport_height = $window.height(),
                viewport_bottom = viewport_top + viewport_height,
                $elem = $(this.selector),
                top = $elem.offset().top,
                height = $elem.height(),
                bottom = top + height;

            if (!this.onScreen()) return 0;

            if (top < viewport_top)
                return Math.round((bottom - viewport_top) / height * 100);
            else
                return Math.round(Math.abs((top - viewport_bottom) / height * 100));

        };

        // If object is viewable in the viewport
        this.onScreen = function() {
            var viewport_top = $window.scrollTop(),
                viewport_height = $window.height(),
                viewport_bottom = viewport_top + viewport_height,
                $elem = $(this.selector),
                top = $elem.offset().top - this.offset,
                height = $elem.height() - this.offset,
                bottom = top + height;

            return (top >= viewport_top && top < viewport_bottom) ||
                   (bottom > viewport_top && bottom <= viewport_bottom) ||
                   (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom);
        }

        this.percentScrolled = function() {
            var $elem = $(this.selector),
                viewport_top = $window.scrollTop(),
                viewport_bottom = viewport_top + $window.height(),
                elemHeight = $elem.height() - this.offset,
                elemTop = $elem.offset().top - this.offset,
                elemBottom = elemTop + elemHeight;

            // If reached footer of page (can't scroll any longer)
            if (viewport_bottom >= $(document).height()) return 100;

            if (viewport_top < elemTop) return 0;
            else if (viewport_top > elemBottom) return 100;
            else {
                var distance = elemBottom - viewport_top;
                return Math.round(100 - ((distance / elemHeight) * 100));
            }
        }
    }

    // Main class, contains the elements of the class
    function ElementsFocused() {
        this.elements = new Array();

        // returns # of objects in the viewport | array of those objects
        this.getElementsOnScreen = function() {
            var e = new Array();

            var count = 0;

            for (var i = 0; i < this.elements.length; i++) {
                if (this.elements[i].onScreen()) {
                    count++;
                    e.push(this.elements[i]);
                }
            }

            return {total: count, elements: e};
        }

        this.getElementsOffScreen = function() {
            var e = new Array();

            var count = 0;

            for (var i = 0; i < this.elements.length; i++) {
                if (!this.elements[i].onScreen()) {
                    count++;
                    e.push(this.elements[i]);
                }
            }

            return {total: count, elements: e};
        }
    }

    $.fn.focusedelements = function( options ) {

        var settings = $.extend({
            offset: 0,
            percentSwitch: 97,
            isFocused: function(){},
            loseFocus: function(){},
            outOfFocus: function(){},
            scrollThrough: function(){}
        }, options);


        var main = new ElementsFocused(),
            lastElement = null,
            viewport_top,
            p_viewport_top = 0, // previous viewport_top
            scrollingDown;

        $(this).each(function(index, elem) {
            main.elements[index] = new Element(elem, settings.offset);
        });


        function detectElementsOffScreen() {
            var m = main.getElementsOffScreen();

            for (var i = 0; i < m.total; i++) {
                settings.outOfFocus.call(m.elements[i].selector);
            }

        }

        function compare(e1, e2) {
            if (e1.percentScrolled() <= settings.percentSwitch) return e1;
            else return e2;
        }

        function detectElementsOnScreen() {

            var m = main.getElementsOnScreen(),
                elem;

            switch (m.total) {
                case 0:
                    elem = null;
                    break;

                case 1:
                    elem = m.elements[0];
                    break;

                case 2:
                default:
                    elem = compare(m.elements[0], m.elements[1]);

                // default:
                //     var length = m.total,
                //         mid1,
                //         mid2;

                //     mid1 = Math.floor(length / 2);
                //     mid2 = mid1 - 1;

                //     elem = compare(m.elements[mid1], m.elements[mid2]);

                //     break;

            }
            if (elem != lastElement) {
                if (lastElement != null) settings.loseFocus.call(lastElement.selector);
                lastElement = elem;
                if (elem != null) settings.isFocused.call(elem.selector);
            }

            for (var i = 0; i < main.elements.length; i++) {
                settings.scrollThrough.call(main.elements[i].selector, main.elements[i].percentScrolled());
            }
        }

        // initial page load
        detectElementsOnScreen();
        detectElementsOffScreen();

        // On scroll
        $window.scroll(function() {
            detectElementsOnScreen();
        });
        // var fireScrollEvent = _.throttle(detectElementsOnScreen, 35);
        // $window.scroll(fireScrollEvent);

        $window.resize(function() {
            detectElementsOnScreen();
            detectElementsOffScreen();
        })
    };

}( jQuery ));
